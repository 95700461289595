import "./styles.scss"

import React from "react"

import Button from "components/Button"

const Main = () => {
  return (
    <section className="not-found-main">
      <div className="container-fluid">
        <h3>Strona nie została znaleziona.</h3>
        <p>
          Coś poszło nie tak i nie udało nam się znaleźć żądanej strony. Prosimy
          o sprawdzenie wpisanego adresu. Jeśli jest on poprawny to
          najwidoczniej nie posiadamy takiej strony. Prosimy o skorzystanie z
          poniższego przycisku.
        </p>
        <Button to="/">Strona główna</Button>
      </div>
    </section>
  )
}

export default Main
